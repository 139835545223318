import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
      <section className="pt-32 pb-32 px-16 font-sans">
        <div className="flex flex-col justify-center items-start">
          <h1 className="text-3xl md:text-5xl font-bold mb-8">Privacy Policy</h1>
          <p>Last Update: 12th December 2023</p>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">General Information</h2>
            <p>
              With this privacy policy, we inform you about how we use, share, and otherwise process the personal data we collect from you. We treat all personal data in accordance with the provisions of European and Austrian data protection law.
            </p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">1. Controller</h2>
            <p>Lalamu Entertainment GmbH</p>
            <p>Stachegasse 21-23/3/29, 1120 Wien, Austria</p>
            <p>Email: <a href="mailto:legal@lalamu.app">legal@lalamu.app</a></p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">2. How we use your Personal Data</h2>
            <p>
              We do not use cookies and do not transfer your data to third parties. We utilize your email address in the course of our services to notify you when your video is ready for download or if the service did not work as expected. Your email address will also be stored and utilized for our newsletters and marketing activities, ensuring you stay informed about our services. If you do not wish to receive any information, please contact us at <a href="mailto:office@lalamu.app">office@lalamu.app</a>.
            </p>
            <p>
              USE BY CHILDREN: We do not offer our products or services for use by children under the age of thirteen, without the involvement of a parent or a guardian. If you believe that we might have any information, please contact us at <a href="mailto:office@lalamu.app">office@lalamu.app</a>.
            </p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">3. Data Protection Regulation Laws</h2>
            <p>
              We collect, process, and use your personal data only in accordance with the GDPR (GENERAL DATA PROTECTION REGULATION law in the European Union (EU) and the European Economic Area (EEA)) and other applicable privacy laws and regulations.
            </p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">4. Data Subjects' Rights</h2>
            <p>
              As a data subject, you have the right to information about your stored personal data as well as the right to correction, data transfer, objection, restriction of processing, and blocking/anonymization or deletion of the data, insofar as no exception (e.g., legal obligation to retain data) exists and in accordance with the legal provisions. If you are of the opinion that the processing of your personal data by us violates the applicable data protection laws or that your data protection rights have been violated in another way, you have the possibility to complain to the competent supervisory authority. In Austria, the competent authority is the data protection authority (<a href="https://www.dsb.gv.at">www.dsb.gv.at</a>).
            </p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">5. Data Security</h2>
            <p>
              Your personal data is protected by appropriate organizational and technical precautions. These precautions relate in particular to protection against unauthorized, illegal, or even accidental access, processing, loss, use, and manipulation.
            </p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">6. Data Retention</h2>
            <p>
              In accordance with the applicable data protection requirements, we are obliged to delete personal data immediately as soon as the purpose for processing has been fulfilled, pursuant to Art. 5 (1) (e) GDPR. In this context, we would like to point out that statutory retention obligations and periods constitute a legitimate purpose for the processing of personal data.
            </p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">7. Data Server Log Files</h2>
            <p>
              In order to operate and maintain the security and functionality of our websites and to provide information about our services, the operator of the respective website automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. This includes your internet protocol address (IP address), browser and language setting, operating system, referrer URL, your internet service provider, and date/time. We reserve the right to check this data retrospectively if we become aware of specific indications of unlawful use. The legal basis is on the one hand the fulfilment of the contract, specifically the free provision of our website (Art 6 (1) (b) GDPR), in our legitimate interest in the security and functionality of our website (Art 6 (1) (f) GDPR) and, finally, these are technically necessary for the operation of the website (§96 (3) Austrian Telecommunications Code).
            </p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">8. Cookies and Third-Parties</h2>
            <p>
              We do not use any cookies and do not transfer your data to third parties. A cookie is a small piece of text used to store information on web browsers, remembering details about your visit to our website. If third parties are involved in the process, such as when the app is used (App Store or Google Store), Lalamu is not responsible for their use of private data. Please contact the respective parties directly.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
