import React from 'react';

function TermsOfUse() {
  return (
    <div>
      <section className="pt-32 pb-32 px-16 font-sans">
        <div className="flex flex-col justify-center items-start">
          <h1 className="text-3xl md:text-5xl font-bold mb-8">Terms of Use</h1>
          <p>12th December 2023 Lalamu Entertainment GmbH, Stachegasse 21-23/3/29, 1120 Vienna, Austria (hereinafter "Lalamu") and the users of the Lalamu Studio.</p>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">1. Validity</h2>
            <p>These Terms and Conditions (GTC) apply to both the free use and the fee-based contracts between Lalamu and users or customers (hereinafter “users” or “you” or “client”) of the software Lalamu Studio (hereinafter “Lalamu Studio” or "software”). The software can only be used on the basis of these GTC. Any conflicting conditions (e.g., GTC) from third parties (e.g., the user) shall only apply with express written confirmation. This also holds true if the User relies on their own GTC to conclude the contract, even if Lalamu does not object to them upon awareness. By using Lalamu Studio for the first time, the user automatically agrees to these GTC.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">2. Lalamu Studio (beta version) on Canva</h2>
            <p>WITH LALAMU STUDIO, YOU CAN LIP-SYNC A VIDEO FEATURING HUMAN AND NON-HUMAN FACES. BY USING LALAMU STUDIO, BOTH FOR FREE OR PAID, YOU ACKNOWLEDGE THAT THE AI LIP-SYNC TOOL IS CURRENTLY IN THE BETA VERSION, SIGNIFYING ONGOING DEVELOPMENT AND PROVIDED “AS IS”. IT IS IMPORTANT TO BE AWARE THAT (1) SUCCESSFUL LIP-SYNCING MAY NOT BE ACHIEVABLE FOR EVERY FACE AT PRESENT, ESPECIALLY THOSE WITH BEARDS, SPECIFIC ANGLES, OR FACES SIGNIFICANTLY DIFFERING FROM HUMAN FACIAL CHARACTERISTICS, AND (2) THE BETA VERSION PROVIDES LOW RESOLUTION FOR THE OUTGOING VIDEOS. CURRENTLY, YOU CAN ACCESS AND USE THE LALAMU STUDIO APP EITHER ON THE WEB PLATFORM OF CANVA OR THE CANVA APP, WHERE LALAMU STUDIO IS INTEGRATED AS AN AI APP.  By agreeing to these terms of use, you acknowledge and accept that the URL of your video will be publicly accessible on Lalamu Studio until you choose to download it. Although the URLs are lengthy and do not reveal any personal data, you are advised to download the video as soon as you receive an email notification stating that your video is ready. PLEASE ENSURE THE PROMPT DOWNLOADING OF THE VIDEOS (the download is essentially an upload of your video into your Media Library on Canva) UPON RECEIVING THE EMAIL NOTIFICATION FROM LALAMU. VIDEOS GENERATED BY LALAMU WILL BE PERMANENTLY REMOVED FROM THE LALAMU STUDIO WEBSITE AFTER A PERIOD OF 7 DAYS. IF YOU HAVE NOT DOWNLOADED THE VIDEO BY THEN, IT WILL NO LONGER BE AVAILABLE.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">3. Right of Use</h2>
            <p>Subject to the terms and conditions of this Agreement, we hereby grant the user a limited, personal, revocable, non-exclusive, non-assignable, non-transferable right to use Lalamu Studio on Canva in accordance with the chosen product and this agreement and any applicable usage rights. The software is available for free to a certain extent, as well as in specified paid versions (see below). For the free mode, registration is not required. The monthly renewed free mode provides credits for 2 minutes, equivalent up to 120 free credits per month. For buying credits, registration is mandatory. For videos or audio uploaded by the user and lipsynced with Lalamu Studio, commercial use is permitted. The user is responsible to hold the necessary rights on the used material. For audio generated within Lalamu Studio, provided through a text-to-speech service provider, commercial use is permitted. For video presets provided in Lalamu Studio, generated through a text-to-video service provider, commercial use is strictly prohibited. These are designed solely for testing the lipsync with Lalamu Studio. Age Requirement: Children may not access or use the service unless their use is directly authorized by their parent or guardian who agrees to be bound by these terms. For purposes of these terms, a child is a person under the age of 13 (or the minimum legal age required to provide consent for processing of personal data in the country where the child is located).</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">4. Obligations of the Users</h2>
            <p className="mb-4">THE USER IS OBLIGATED TO ENSURE THAT THE VIDEOS GENERATED BY THEIR ACCOUNT WITH LALAMU STUDIO CAN ONLY BE PUBLISHED WITH THE NOTICE THAT AN AI TOOL WAS USED.</p>
            <p className="mb-4"> The user is required to comply with legal regulations while using Lalamu Studio. This involves refraining from violating applicable legal provisions or infringing upon the rights of third parties, such as copyrights, film, video, and music rights, the personal rights of individuals featured in the videos (consent of the individuals portrayed), and data protection regulations.</p>
            <p className="mb-4">Moreover, the user is prohibited from utilizing Lalamu in any manner that:</p>
            <p className="mb-4">is harassing, abusive, racially or ethnically offensive, defamatory, invasive of personal privacy or publicity rights, libelous, or threatening;</p>
            <p className="mb-4">discriminates, incites, or promotes discrimination against others based on race, religion, sex, sexual orientation, age, disability, ancestry, national origin, or any other basis;</p>
            <p className="mb-4">is sexually explicit or pornographic in nature or contains links to such material;</p>
            <p className="mb-4">seeks to harm or exploit children;</p>
            <p className="mb-4">involves the sale or promotion of illegal activities, products, or services;</p>
            <p className="mb-4">is fraudulent or promotes fraudulent activity;</p>
            <p className="mb-4">promotes or creates a risk of physical or mental harm, emotional distress, death, disability, or disfigurement to themselves, any person, or animal;</p>
            <p className="mb-4">contains any information or disinformation that is false, deceptive, or misleading or otherwise promotes, endorses, encourages, or facilitates the spread of false information;</p>
            <p className="mb-4">violates any applicable law or promotes activities that are illegal in nature;</p>
            <p className="mb-4">threatens or undermines democratic processes or institutions.</p>
            <p className="mb-4">The user must treat the access data (password, username) as strictly confidential and protect it from unauthorised access by third parties. If the user passes on the access data (password, username) to third parties, the user shall be liable towards Lalamu for all damage caused as a result.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">5. Intellectual Property Rights</h2>
            <p>The Software is not sold to you under this Agreement and you acknowledge that Lalamu and its licensors retain all title, ownership rights and Intellectual Property Rights (defined below) in and to the Software (and its related software). We reserve all rights not expressly granted herein to the Software. “Intellectual Property Rights” means any and all rights in and to any and all trade secrets, patents, copyrights, service marks, trademarks, know-how, or similar intellectual property rights, as well as any and all moral rights, rights of privacy, publicity and similar rights of any type under the laws or regulations of any governmental, regulatory, or judicial authority, whether foreign or domestic.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">6. Use of Third Party Intellectual Property</h2>
            <p>Users of the Lalamu Studio app are strictly prohibited from using any third-party intellectual property (IP) in the generative AI process of Lalamu Studio. This includes, but is not limited to, copyrighted materials, trademarks, trade secrets, or any other proprietary information owned by third parties. Users must ensure that all content used in the generative AI process is either owned by them or used with proper authorization and licensing. By agreeing to these terms, users acknowledge their responsibility to respect the intellectual property rights of others and agree to indemnify Lalamu against any claims arising from their misuse of third-party IP.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">7. Duration and termination</h2>
            <p className="mb-4">The paid packages are valid for one month, and any unused credits expire at the end of the month. Therefore, there is no need for termination. The contractual relationship concludes at the end of each month and commences with the next purchased package by the user (see also point 3).</p>
            <p className="mb-4">Lalamu is entitled to terminate the contractual relationship with immediate effect for good cause. Good cause is deemed to be in particular:</p>
            <p className="mb-4">Providing false data about oneself;</p>
            <p className="mb-4">Violation of third-party rights (in particular copyright or data protection);</p>
            <p className="mb-4">Unlawful use of the videos created with Lalamu</p>
            <p className="mb-4">If the contractual relationship is terminated by Lalamu for good cause, the User's account will be blocked immediately, and any credits will be forfeited.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">8. Prices</h2>
            <p>The Canva-app is available for download free of charge. In connection with the use of the app, data transfer costs may be incurred by the network operator, which are to be borne by the user. Credits can be purchased in the app (both web and mobile), which can then be used to create videos (see also point 3 and 4). All prices include VAT.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">9. Support</h2>
            <p>If you have any questions, please contact our support team at: office@lalamu.app.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">10. Publicity</h2>
            <p>You agree that we are allowed to use your name, trademark and/or logo (if applicable) and to refer to you as a customer of Lalamu, (i) in sales presentations, promotional/marketing materials, and press releases, and (ii) on Lalamu’s website, including a link to your website (if applicable), for promotional purposes.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">11. Service Availability / Closure of the Service</h2>
            <p>Lalamu does not guarantee the constant availability of its services. Downtimes due to maintenance, software updates and due to circumstances (such as technical problems of third parties, force majeure), which are not within the sphere of influence of Lalamu and are therefore also beyond its control, and which cause non-accessibility of the services offered by it via the Internet, cannot be excluded. Lalamu is entitled to shut down and close the service, which is the subject of this contract, in whole or in part, if its security or the security of Users is endangered. This right of Lalamu also exists if the continued operation of the service or parts of the service is economically unreasonable for Lalamu. Lalamu will immediately notify the User of such measures or terminate the contract in accordance with point 4.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">12. Right of withdrawal of the consumer pursuant to § 11 FAGG (Austrian Distance Selling Code)</h2>
            <p className="mb-4">The user, who is a consumer within the meaning of the Consumer Protection Act (KSchG), has the right to withdraw from a contract concluded outside the business premises of the entrepreneur or from a distance contract, within fourteen calendar days without providing any reasons, unless a statutory exemption applies. The withdrawal period is fourteen calendar days, starting from the conclusion of the contract in the case of contracts on digital content (applicable to Lalamu Studio). It is sufficient if the consumer (user) has sent the declaration of withdrawal within this period. However, if the delivery of digital content not stored on a physical data carrier begins prematurely before the withdrawal period expires, and the consumer consents to this and is informed about the loss of the right of withdrawal, the consumer will have no right of withdrawal (Exemption from the right of withdrawal according to § 18 FAGG). The declaration of withdrawal can also be made electronically using the model withdrawal form provided below:</p>
            <p className="mb-4">"I/we () hereby revoke the contract concluded by me/us () for the purchase of digital content from Lalamu Entertainment GmbH.Purchase on ...Name of the consumer(s) ...Address of the consumer(s) ...,Date...Signature of the consumer(s)". Send us the finished text in writing or by e-mail: office@lalamu.app.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">13. Liability & Warranty</h2>
            <p className="mb-4">THE SERVICE IS PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. AS LALAMU STUDIO IS STILL IN THE BETA VERSION, ACCURATE RESULTS CANNOT BE GUARANTEED. LALAMU EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES REGARDING THE QUALITY OF THE RESULTS DELIVERED BY THE SOFTWARE, INCLUDING VERY INACCURATE LIPSYNC RESULTS, LOW-QUALITY RESULTS, AND DELAYED DELIVERY DUE TO SERVER OVERLOAD (SEE SECTION 2). LALAMU DOES NOT WARRANT THAT THE USE OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.</p>
            <p className="mb-4">LALAMU SHALL NOT BE LIABLE IN THE EVENT THAT THE URL OF THE VIDEO GENERATED WITH LALAMU'S SOFTWARE IS DISCOVERED WHILE BEING PUBLIC UNTIL THE DOWNLOAD BY THE USER. (SEE SECTION 2).</p>
            <p className="mb-4">THE LIP-SYNCED VIDEOS ARE ONLY AVAILABLE FOR 7 DAYS; AFTERWARD, THEY WILL BE DELETED. LALAMU EXPRESSLY DISCLAIMS THE STORAGE OF THE VIDEOS AFTER 7 DAYS. (SEE SECTION 2).</p>
            <p className="mb-4">THE USE OF THE SERVICE NECESSARILY INVOLVES THE TRANSMISSION OF THE USER´S DATA OVER NETWORKS THAT LALAMU DOES NOT OWN, OPERATE, OR CONTROL. LALAMU IS NOT RESPONSIBLE FOR ANY LOSS, ALTERATION, INTERCEPTION, OR STORAGE OF THE USER´S DATA ACROSS SUCH NETWORKS. LALAMU WILL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES, OR OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE LALAMU’S REASONABLE CONTROL.</p>
            <p className="mb-4">LALAMU SHALL NOT BE LIABLE TO ENTREPRENEURS/COMPANIES FOR LOSS OF PROFIT, THIRD-PARTY DAMAGE, INDIRECT DAMAGE, AND/OR CONSEQUENTIAL DAMAGE, OR DAMAGE RESULTING FROM SLIGHT NEGLIGENCE. THE USER SHALL FULLY INDEMNIFY AND HOLD HARMLESS LALAMU FROM AND AGAINST CLAIMS OF THIRD PARTIES BASED ON A BREACH OF CONTRACTUAL OR LEGAL PROVISIONS AS WELL AS AN INFRINGEMENT OF THE RIGHTS OF THIRD PARTIES.</p>
            <p className="mb-4">Claims for damages must be asserted within 6 months of knowledge of the damage and the damaging party; otherwise, they will be forfeited. In the event of a legal warranty, the entrepreneurial user must immediately check the content for any obvious defects. Defects must be communicated to Lalamu within 14 days of retrievability with sufficient justification. Minor technical changes are deemed to have been approved in advance. Any warranty period shall be a maximum of 12 months from acceptance.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">14. Data protection</h2>
            <p className="mb-4">Lalamu complies with the applicable provisions of data protection law, in particular the Data Protection Act (DSG) and the General Data Protection Regulation (GDPR). Specific information on how users' data is processed can be found during registration and in the privacy policy.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">15. Changes to the GTC</h2>
            <p className="mb-4">In the event of changes to these GTC, Users will be informed of these and requested to confirm them. If these are not confirmed, the user may terminate this contract with immediate effect.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">16. Applicable law & Jurisdiction</h2>
            <p className="mb-4">This contract is governed by Austrian substantive law, excluding the mandatory referral norms of international private law (e.g. IPRG, Rome I Regulation) and the UN Convention on Contracts for the International Sale of Goods. According to Art 6 (2) Rome I Regulation, this choice of law does not deprive a consumer of the protection of mandatory provisions of the law of the state of his habitual residence; these continue to be applicable. If the user is a company in the sense of § 1 KSchG (Austrian Consumer Protection Act), a legal entity under public law, the exclusive factual and local jurisdiction of the court for 1010 Vienna is agreed for all disputes arising from contractual relationships between the customer and Lalamu.</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">17. Final provisions</h2>
            <p className="mb-4">Should any provisions of this contract be legally ineffective, invalid and/or void or become so in the course of the duration, this shall not affect the legal effectiveness and validity of the remaining provisions.</p>
          </div>

        </div>
      </section>
    </div>
  );
}

export default TermsOfUse;
