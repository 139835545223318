
import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav>
      <div className="py-[1.5rem] flex items-center justify-center border-b-[1px] border-[#575757]"> 
      <Link to="/" ><img src="/images/lalamu.svg" alt="Logo" className="h-10 w-[85vw]" /></Link>
      </div>
    </nav>
  );
}

export default Navbar;

