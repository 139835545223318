import React from 'react';

function Imprint() {
  return (
    <div>
      <section className="pt-32 pb-32 px-16 font-sans">
        <div className="flex flex-col justify-center items-start">
          <h1 className="text-3xl md:text-5xl font-bold mb-8">Imprint</h1>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Publisher / Media Owner</h2>
            <p>Lalamu Entertainment GmbH</p>
            <p>Stachegasse 21-23/3/29</p>
            <p>1120 Vienna</p>
            <p>+43 660 1980 923</p>
            <p>Email: <a href="mailto:office@lalamu.app">office@lalamu.app</a></p>
            <p>FN 529347b</p>
            <p>Commercial Court of Vienna</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Value Added Tax Identification Number</h2>
            <p>ATU75880959</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Competent Authority</h2>
            <p>Magistrate District Office of the XXIII. District</p>
            <p>Austrian Economic Chambers: companies A-Z: <a href="https://firmen.wko.at/lalamu-entertainment-gmbh/wien/?firmaid=ca10b0cc-b9a5-4372-8592-180f34ab4040" target="_blank" rel="noopener noreferrer">Lalamu Entertainment GmbH</a></p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Objective of the Company</h2>
            <p>Software development and marketing</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">CEO</h2>
            <p>Matthias Spitzer</p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Applicable Trade and Professional Regulations</h2>
            <p>Software development and marketing, e.g. Austrian Civil (ABGB) and Commercial Code (UGB), Austrian Trade Regulations (GewO); available at <a href="https://www.ris.bka.gv.at" target="_blank" rel="noopener noreferrer">www.ris.bka.gv.at</a></p>
            <p>For further information regarding our trade and professional regulations, please refer to: <a href="https://firmena-z.wko.at" target="_blank" rel="noopener noreferrer">firmena-z.wko.at</a></p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Consumer Dispute Resolution</h2>
            <p>Consumers have the possibility to submit complaints to the EU's online dispute resolution platform: <a href="https://ec.europa.eu/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/odr</a></p>
            <p>You can also submit your complaint directly to us at the following email address: <a href="mailto:legal@lalamu.app">legal@lalamu.app</a></p>
          </div>

          <div className="flex flex-col mt-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Disclaimer</h2>
            <p className="mb-4">The contents of this website were created with the greatest possible care and in compliance with the rights of third parties (e.g. copyright). Although this website is regularly checked for topicality, completeness and correctness, this cannot be guaranteed. We therefore ask you to contact us directly in case of any uncertainties.</p>
            <p className="mb-4">Liability for the contents of this website and, in particular, for the links set is only applicable within the scope of § 17 ECG (E-Commerce-Code). The links are also set and checked with the greatest possible care. Nevertheless, we cannot guarantee that the linked site will not change without our knowledge and action.</p>
            <p className="mb-4">At the time of linking, these links were in conformity with the law. Should we be (made) aware of a legal problem, the link in question will be removed immediately.</p>
            <p>We have the necessary rights of use for the content on this website. Any use of the contents of our website (especially pictures) without our express consent is not permitted.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Imprint;
