import React from 'react';

function Hero() {
  return (
    <div className="flex justify-center items-center pt-16 pb-32 md:pt-32 md:pb-64 px-4 md:px-8">
      {/* Container */}
      <div className="bg-white shadow-custom rounded-2xl p-6 flex flex-col md:flex-row md:items-center gap-6 md:gap-8 max-w-xs md:max-w-[99%] md:p-[40px] h-auto flex-shrink-0 ">
        {/* Bild für mobile */}
        <div className="w-full md:hidden">
          <img
            src="./images/Effects-Split-p-500.jpg"
            alt="Video Preview"
            className="rounded-xl w-full h-auto"
          />
        </div>

        {/* Text */}
        <div className="text-center md:text-left flex-1 md:w-1/2 md:h-[100%] flex-shrink-0 justify-between self-align gap-8">
          <h1 className="text-3xl md:text-4xl font-bold font-sans leading-snug mb-8 md:mb-8">
            Full HD <br className=""/> lip sync on
          </h1>
          <img src="./images/lip.svg" alt="Logo" className="hidden md:block md:h-8 mx-auto md:mx-0 mb-4 md:mt-0" />
          <img src="./images/lippurple.svg" alt="Logo" className="md:hidden h-8 mx-auto mb-8" />
          
           <a href="https://www.lipsyncer.ai/" target="_blank" rel="noopener noreferrer">
          <button className="bg-[#7330DF] w-[17rem] text-white text-lg font-semibold py-[1rem] px-[4rem] md:mt-8 rounded-[0.5rem] md:w-auto hover:bg-[#8A4BFF] transform transition-transform duration-200 hover:scale-105">
            Try for free
          </button>
          </a>
        </div>

        {/* Bild für Desktop */}
        <div className="hidden md:block flex-1 md:w-1/2 md:h-[100%] items-stretch flex-shrink-0 md:flex md:justify-center md:items-center md:items-stretch">
          <img
            src="./images/Effects-Split-p-500.jpg"
            alt="Video Preview"
            className="rounded-xl w-full min-h-[100%] h-full"
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;