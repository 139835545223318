"use client";

import React from "react";

const UserMilestone = () => {
  return (
    <div className="bg-[#7330DF] text-white text-center py-16 px-8 md:px-16">
      <div className="text-4xl md:text-5xl font-bold max-w-4xl m-auto mb-20 mt-16">
        After 4 months, we reached
      </div>
      <span
        className="text-[65px] lg:text-[150px] font-extrabold text-[#7330DF]"
        style={{
          backgroundImage: `url(images/Gelber-Balken-Unter-A-lot-of-options.svg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingLeft: ".5rem",
          paddingRight: ".5rem",
          paddingTop: "2.5rem",
          paddingBottom: "0rem",
        }}
      >
        400.000
      </span>
      <div className="text-4xl md:text-6xl font-bold mt-16">happy users on</div>
      <img
        src="/images/Canva-Logo.svg"
        alt="Canva Logo"
        className="mt-12 mx-auto w-3/4 md:w-1/3 mb-12"
      />
    </div>
  );
};

export default UserMilestone;
