import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white py-32 md:pt-32 md:pb-16 border-t border-gray-200 font-sans">
      {/* Container Mobile*/}
      <div className="max-w-[85vw] mx-auto md:hidden"> 
        {/* Menu-Links-Wrapper */}
        <div className="flex flex-col gap-16 items-center text-[#5D5D5D] text-[1rem] md:flex-row md:order-2">
          <Link to="/legal-pages/TermsOfUse">Terms of Use</Link>
          <Link to="/legal-pages/PrivacyPolicy">Privacy Policy</Link>
          <Link to="/legal-pages/Impressum">Impressum</Link>
        </div>
        {/* Logo-Copyright-Text-Wrapper */}
        <div className="mt-32 md:mt-0 flex-col md:order-1">
          <img
            src="./images/lalamu.svg"
            alt="Lalamu Studios"
            className="mx-auto max-w-[75vw]"
          />
          <p className="text-center text-[1rem] text-[#0A0A0A] mt-8 mx-auto max-w-[75vw]">© Lalamu Entertainment GmbH</p>
        </div>
      </div>

      {/* Container Desktop*/}
      <div className="hidden md:flex max-w-[85vw] mx-auto"> 
        <div className="flex-col w-[85vw]">
          <Link to="/" ><img
            src="/images/lalamu.svg"
            alt="Lalamu Studios"
            className="w-64"
          /> </Link>
          <div className="flex justify-between text-left items-start w-[100%] mt-8">
            <p className="text-left text-[1rem] text-[#0A0A0A]">© Lalamu Entertainment GmbH</p>
            <div className="flex gap-8 text-[1rem] text-[#5D5D5D]">
              <Link to="/legal-pages/TermsOfUse">Terms of Use</Link>
              <Link to="/legal-pages/PrivacyPolicy">Privacy Policy</Link>
              <Link to="/legal-pages/Imprint">Imprint</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
